import * as React from 'react';
import { graphql } from 'gatsby';
import {
  BuilderComponent,
  withChildren,
  Builder,
  BuilderBlocks
} from '@builder.io/react';

import { Helmet } from 'react-helmet';
import Link from '../components/Link/Link';
import '@builder.io/widgets';
const defaultDescription = 'E&P Group Gambia';

const { Suspense } = React;

const defaultTitle = 'E&P Group';

const renderLoader = () => <p>Loading</p>;
//  const DetailsComponent = (props) => (
//   <Suspense fallback={renderLoader()}>
//     <ShopCategories {...props} children={
//       <BuilderBlocks
//         // TODO: parent={this.props.builderBlock}
//         parentElementId={props.builderBlock.id}
//         // TODO: start with just "tabs." when bump react version
//         dataPath={`component.options.tabs`}
//         blocks={'Helo'}
//       />
//     }/>
//   </Suspense>
// )





function LandingPageTemplate({ location, data }) {
  const models = data?.allBuilderModels;
  const landingPage = models.page[0]?.content;

  return (
    <>
      <Helmet>
        <title>{(landingPage && landingPage.data.title) || defaultTitle}</title>
        <meta
          name="description"
          content={
            (landingPage && landingPage.data.description) || defaultDescription
          }
        />
      </Helmet>
      {/** name of the model is landing page, change it if you decided to build*/}
      <BuilderComponent
        renderLink={Link}
        name="page"
        content={landingPage}
      />
    </>
  );
}

export default LandingPageTemplate;

export const landingPageQuery = graphql`
  query($path: String!) {
    allBuilderModels {
      page(
        target: { urlPath: $path }
        limit: 1
        options: { cachebust: true }
      ) {
        content
      }
    }
  }
`;
